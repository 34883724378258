import React from "react";

const InvoiceHeader = ({ heading, opacity, displaynone }) => {
  return (
    <div>
      <div>
        <h2
          className={`text-center ff_aclonica fs_80_invoice text-white ${opacity} ${displaynone}`}
        >
          {heading}
        </h2>
      </div>
    </div>
  );
};

export default InvoiceHeader;
