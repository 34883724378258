import React, { useState, useEffect } from "react";

import { Container, Col } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Invoice/InvoiceHeader/InvoiceHeader";
import logoIcon from "../assets/images/png/logo.png";
import logo from "../assets/images/png/logo.png";
import Intrduction from "../assets/images/svg/introduction.svg";
import Investment from "../assets/images/svg/investment.svg";
import steps from "../assets/images/svg/setps.svg";
import TandC from "../assets/images/svg/termandconditions.svg";
import InvoiceNavbar from "../components/Invoice/InvoiceNavbar/InvoiceNavbar";
import MarketingNavbar from "../components/Marketing/MarketingNavbar/MarketingNavbar";

const Marketing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    journal: false,
  });

  const menuItems = [
    {
      name: "Introduction",
      link: "/",
      selectedImage: Intrduction,
      unselectedImage: Intrduction,
    },
    {
      name: "Investment ",
      link: "/investment",
      selectedImage: Investment,
      unselectedImage: Investment,
    },
    {
      name: "Next steps",
      link: "/next-step",
      selectedImage: steps,
      unselectedImage: steps,
    },
    {
      name: "Terms & Conditions",
      link: "/term-and-conditions",
      selectedImage: TandC,
      unselectedImage: TandC,
    },
  ];

  const handleClick = (link, index) => {
    navigate(`/bambooruts/invoice${link}`);
    setSelected(index);

    if (menuItems[index].name === "Journal") {
      setDropdownVisible((prevState) => ({
        ...prevState,
        journal: true,
      }));
    } else {
      setDropdownVisible({ journal: false });
    }
  };

  const toggleSidebar = () => {
    setHideSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    const path = location.pathname.replace("/counselor-portal", "");

    const activeTab = menuItems.findIndex(
      (item) =>
        item.link === path ||
        (item.name === "Journal" && path.startsWith("/journal")) ||
        (item.dropdown &&
          item.dropdown.some((dropdownItem) => dropdownItem.link === path))
    );

    if (activeTab !== -1) {
      setSelected((prevSelected) => {
        if (prevSelected !== activeTab) {
          if (menuItems[activeTab].name === "Journal") {
            setDropdownVisible((prevState) => ({
              ...prevState,
              journal: true,
            }));
          } else {
            setDropdownVisible((prevState) => ({
              ...prevState,
              journal: false,
            }));
          }
          return activeTab;
        }
        return prevSelected;
      });
    }
  }, [location.pathname, menuItems]);

  return (
    <div className={`min-vh-100 bg-image-invoice`}>
      <Container fluid className="px-0 overflow-hidden">
        <div>
          <div
            className={`row min-vh-100  xs-margin-custom ${
              hideSidebar === true ? "padding-left-102" : ""
            }`}
          >
            <Col
              lg={2}
              md={3}
              className="border-end border-end-1 ps-0 h-100 d-lg-block mb-md-0 bg-transparent side-bar-menu"
            >
              <div
                style={{ height: "100vh", left: 0, top: 0 }}
                className={`card d-flex z-1 pb-3 d-none d-md-block overflow-auto bg-transparent col-lg-2 col-3 rounded-0 position-fixed border-0 custom_space`}
              >
                <div>
                  <div className="d-flex d-lg-none justify-content-center">
                    <img
                      onClick={() => navigate("/")}
                      width={172}
                      className="pt-3 pb-3"
                      style={{ cursor: "pointer" }}
                      src={logoIcon}
                      alt="logo"
                    />
                  </div>
                  <div className="d-none d-lg-flex">
                    {hideSidebar === true ? (
                      <img
                        onClick={() => navigate("/")}
                        width={172}
                        className="pt-3"
                        style={{ cursor: "pointer" }}
                        src={logoIcon}
                        alt="logo"
                      />
                    ) : (
                      <img
                        onClick={() => navigate("/")}
                        width={172}
                        className="pt-3"
                        style={{ cursor: "pointer" }}
                        src={logo}
                        alt="logo"
                      />
                    )}
                  </div>
                  {/* Sidebar Menu */}
                  <div className="mt-4">
                    {menuItems.map((item, index) => {
                      const isActive =
                        selected === index ||
                        (item.dropdown &&
                          item.dropdown.some((dropdownItem) => {
                            if (
                              dropdownItem.link &&
                              location.pathname.startsWith(
                                `/fieldhouse-dashboard/${dropdownItem.link}`
                              )
                            )
                              return true;
                            if (dropdownItem.subDropdown) {
                              return dropdownItem.subDropdown.some((subItem) =>
                                location.pathname.startsWith(
                                  `/fieldhouse-dashboard/${subItem.link}`
                                )
                              );
                            }
                            return false;
                          }));

                      const imageSrc = isActive
                        ? item.selectedImage
                        : item.unselectedImage;

                      return (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className={`${
                            isActive
                              ? "border_custom_counselor"
                              : "border_custom_counselor_unselected"
                          }`}
                          onClick={() => handleClick(item.link, index)}
                        >
                          {/* Menu Item Wrapper */}
                          <div
                            className={`py-3 bg-blur-side-items mt-3 d-flex align-items-center justify-content-center justify-content-lg-start position-relative gap-3 ${
                              hideSidebar
                                ? " px-3"
                                : " me-lg-4 ps-lg-3 pe-md-2 ps-md-2"
                            } menu-item space_grotesk ${
                              isActive ? "text-white" : "text-white"
                            }`}
                          >
                            {item.selectedImage && item.unselectedImage && (
                              <img
                                style={{ width: "44px" }}
                                src={imageSrc}
                                alt={`${item.name} icon`}
                              />
                            )}
                            <p
                              className={`mb-0 fs_23 xs-display-none ${
                                isActive
                                  ? "text-white ff_abel"
                                  : "text-white ff_abel"
                              } ${hideSidebar ? "d-none" : "d-lg-block"}`}
                            >
                              {item.name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
            <div className="d-md-none">
              <MarketingNavbar menuItems={menuItems} selected={selected} />
            </div>
            <Col
              style={{ background: "#F4F8FA", minHeight: "100vh" }}
              xs={12}
              md={9}
              lg={hideSidebar === true ? 12 : 10}
              className="position-relative ps-lg-0 bg-transparent"
            >
              <div className="ps-md-2">
                <div className="ps-4 pe-4 ">
                  <Outlet />
                </div>
              </div>
            </Col>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Marketing;
