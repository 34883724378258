import React from "react";
import InvoiceHeader from "../InvoiceHeader/InvoiceHeader";

const MarketingStrategy = () => {
  return (
    <div className="py-3">
      <InvoiceHeader
        heading="Strategic Growth Plan for the Dental Clinic"
        opacity="opacity-1"
        displaynone="d-none d-md-block"
      />
      <div className="bg-blur-side-items rounded-3 p-lg-5 p-3">
        <div className="p-lg-5">
          <div>
            <h2 className="ff_cinzel fs_48">Marketing Strategy</h2>
          </div>
          <div className="mt-3">
            <div
              style={{ background: "#078513", maxWidth: "fit-content" }}
              className="px-3 rounded-2"
            >
              <p className="ff_abel mb-0 fs_23 text-white">
                1️ Wedding Smile Makeover: Scaling & Cleaning Offer
              </p>
            </div>
            <p className="ff_abel fs_23 mt-2">
              <strong>Objective:</strong> To attract individuals preparing for
              their weddings by offering an{" "}
              <span className="fw-semibold">
                {" "}
                affordable and effective dental cleaning service
              </span>
              , ensuring they have a fresh, bright smile for their special day.
            </p>
            <p className="ff_abel fs_23">
              <strong>Offer:</strong> Wedding Smile Glow-Up – Professional
              Scaling & Cleaning @ ₹499 (Limited Time Offer)
            </p>
            <ul className="invoice-class mt-3">
              <li className="ff_abel ms-3 fs_23">
                Comprehensive Teeth Scaling & Cleaning for a polished, healthy
                appearance.
              </li>
              <li className="ff_abel ms-3 fs_23">
                Quick 30-minute procedure with minimal downtime.
              </li>
              <li className="ff_abel ms-3 fs_23">
                Special Couple Discount: If both partners book together, they
                receive an additional 10% discount.
              </li>
            </ul>
            <div>
              <p className="ff_abel fs_23">
                <strong>Execution Plan:</strong>
              </p>
              <ul className="invoice-class ms-3">
                <li className="ff_abel fs_23">
                  Targeted Social Media Advertising: Facebook & Instagram ads
                  focusing on engaged couples.
                </li>
                <li className="ff_abel fs_23">
                  On-Ground Promotions: Placement of standees in salons and
                  beauty centers to attract walk-ins.
                </li>
              </ul>
            </div>

            <p className="ff_abel fs_23">
              <strong>Expected Outcomes:</strong>
              <ul className="invoice-clas ms-2 p-0 mt-3">
                <li className="ff_abel ms-3 fs_23">
                  ✔ Increased patient visits for scaling & cleaning services.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Enhanced brand positioning within the premium dental
                  aesthetics space.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Higher conversion rates for upselling teeth whitening and
                  cosmetic procedures.
                </li>
              </ul>
            </p>
          </div>
          <div className="mt-4">
            <div
              style={{ background: "#078513", maxWidth: "fit-content" }}
              className="px-3 rounded-2"
            >
              <p className="ff_abel mb-0 fs_23 text-white">
                2. Dental Subscription Plan: Progressive Discount Model
              </p>
            </div>
            <p className="ff_abel fs_23 mt-2">
              <strong>Objective:</strong> To encourage{" "}
              <strong> repeat visits and long-term patient loyalty </strong>by
              offering an <strong> incremental discount structure</strong> that
              benefits returning patients and their families.
            </p>
            <p className="ff_abel fs_23">
              <strong>Offer:</strong> Family Subscription Card – One Card,
              Multiple Benefits
            </p>
            <ul className="invoice-class mt-3">
              <li className="ff_abel ms-3 fs_23">
                ₹499 One-Time Subscription Fee – Covers up to four family
                members.
              </li>
              <li className="ff_abel ms-3 fs_23">
                Progressive Discount Structure:
                <li className="ff_abel ms-3 fs_23">
                  <strong>1st Visit:</strong> 10% Off
                </li>
                <li className="ff_abel ms-3 fs_23">
                  <strong>2nd Visit:</strong> 15% Off
                </li>
                <li className="ff_abel ms-3 fs_23">
                  <strong>3rd Visit & Beyond:</strong> 20% Off (Maximum Cap)
                </li>
              </li>
            </ul>
            <div>
              <p className="ff_abel fs_23">
                <strong>Execution Plan:</strong>
              </p>
              <ul className="invoice-class ms-3">
                <li className="ff_abel fs_23">
                  Onboarding at Reception: Reception staff will promote and
                  onboard patients into the program.
                </li>
                <li className="ff_abel fs_23">
                  WhatsApp & SMS Marketing: Regular reminders to members about
                  their eligibility for discounts.
                </li>
                <li className="ff_abel fs_23">
                  Referral Incentive: Existing members who refer another family
                  will receive an additional 5% discount on their next visit.
                </li>
              </ul>
            </div>

            <p className="ff_abel fs_23">
              <strong>Expected Outcomes:</strong>
              <ul className="invoice-clas ms-2 p-0 mt-3">
                <li className="ff_abel ms-3 fs_23">
                  ✔ Increased repeat visits and patient retention.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Higher family engagement, leading to long-term revenue
                  stability.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ A sustainable increase in new patient referrals.
                </li>
              </ul>
            </p>
          </div>
          <div className="mt-4">
            <div
              style={{ background: "#078513", maxWidth: "fit-content" }}
              className="px-3 rounded-2"
            >
              <p className="ff_abel mb-0 fs_23 text-white">
                3. 24/7 Emergency Dental Care Services
              </p>
            </div>
            <p className="ff_abel fs_23 mt-2">
              <strong>Objective:</strong> To position the clinic as a{" "}
              <strong> trusted provider for emergency dental care</strong>,
              ensuring accessibility beyond regular business hours.
            </p>
            <p className="ff_abel fs_23">
              <strong>Offer:</strong>Round-the-Clock Emergency Dental Assistance
            </p>
            <ul className="invoice-class mt-3">
              <li className="ff_abel ms-3 fs_23">
                24/7 Availability: Immediate response for urgent dental issues
                such as severe pain, tooth fractures, and accidental trauma.
              </li>
              <li className="ff_abel ms-3 fs_23">
                Priority Walk-Ins: Emergency cases receive expedited
                appointments and priority treatment slots.
              </li>
            </ul>
            <div>
              <p className="ff_abel fs_23">
                <strong>Execution Plan:</strong>
              </p>
              <ul className="invoice-class ms-3">
                <li className="ff_abel fs_23">
                  Google My Business Optimization: Highlight “24/7 Emergency
                  Dental Care” in search listings.
                </li>
                <li className="ff_abel fs_23">
                  Localized Digital Advertising: Targeting audiences searching
                  for “Dental Clinic near me ” or “Best Dental Clinic in Lalru”.
                </li>
              </ul>
            </div>

            <p className="ff_abel fs_23">
              <strong>Expected Outcomes:</strong>
              <ul className="invoice-clas ms-2 p-0 mt-3">
                <li className="ff_abel ms-3 fs_23">
                  ✔ Strengthens <strong>clinic credibility</strong> by offering
                  round-the-clock service.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Increases <strong> emergency case conversions</strong> into
                  long-term patients.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Expands the{" "}
                  <strong>
                    {" "}
                    clinic’s brand reach within the local community.
                  </strong>
                </li>
              </ul>
            </p>
          </div>
          <div className="mt-4">
            <div
              style={{ background: "#078513", maxWidth: "fit-content" }}
              className="px-3 rounded-2"
            >
              <p className="ff_abel mb-0 fs_23 text-white">
                4. School Collaboration: Dental Health Report Cards for Students
              </p>
            </div>
            <p className="ff_abel fs_23 mt-2">
              <strong>Objective:</strong> To{" "}
              <strong> increase parental engagement</strong> in children’s oral
              health through structured <strong>school partnerships,</strong>
              leading to higher patient footfall.
            </p>
            <p className="ff_abel fs_23">
              <strong>Offer:</strong> Free School Dental Check-Ups +
              Personalized Health Report Cards for Parents
            </p>
            <ul className="invoice-class mt-3">
              <li className="ff_abel ms-3 fs_23">
                <strong>In-School Oral Check-Ups:</strong> Conducted by clinic
                professionals at partner schools.
              </li>
              <li className="ff_abel ms-3 fs_23">
                <strong>Personalized Dental Health Report Cards:</strong> Sent
                home with each student, outlining key findings such as{" "}
                <strong>
                  cavity risk, gum health, and potential orthodontic concerns.
                </strong>
              </li>
              <li className="ff_abel ms-3 fs_23">
                <strong>Special Discount for Teachers & Their Families:</strong>
                <span> 15% Discount on All Treatments</span> for teachers and
                their immediate family members.
              </li>
            </ul>
            <div>
              <p className="ff_abel fs_23">
                <strong>Execution Plan:</strong>
              </p>
              <ul className="invoice-class ms-3">
                <li className="ff_abel fs_23">
                  <strong>School Partnerships:</strong> Direct outreach to
                  school administrations for collaboration.
                </li>
                <li className="ff_abel fs_23">
                  <strong>Parental Engagement via WhatsApp:</strong> Follow-ups
                  with parents regarding their child’s report and potential
                  treatments.
                </li>
                <li className="ff_abel fs_23">
                  <strong>Faculty Awareness Campaigns:</strong> Educating
                  teachers about oral health best practices and extending
                  exclusive discounts.
                </li>
              </ul>
            </div>

            <p className="ff_abel fs_23">
              <strong>Expected Outcomes:</strong>
              <ul className="invoice-clas ms-2 p-0 mt-3">
                <li className="ff_abel ms-3 fs_23">
                  ✔ Higher{" "}
                  <strong>parental awareness and immediate action</strong> for
                  children’s oral care.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Strengthened <strong>school-clinic partnerships,</strong>{" "}
                  leading to <strong>long-term patient acquisition.</strong>
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✔ Increased <strong>teacher and faculty engagement,</strong>{" "}
                  generating additional patient flow.
                </li>
              </ul>
            </p>
          </div>
          {/* Conclusion & Next Steps content */}
          <div className="mt-4">
            <div
              style={{ background: "#078513", maxWidth: "fit-content" }}
              className="px-3 rounded-2"
            >
              <p className="ff_abel mb-0 fs_23 text-white">
                Conclusion & Next Steps
              </p>
            </div>
            <div className="mt-3">
              <h3 className="ff_abel fs_23">📌 Strategic Growth Pillars:</h3>
              <ul className="invoice-class mt-3">
                <li className="ff_abel ms-3 fs_23">
                  ✅ Wedding Smile Offers – Attract new patients for scaling &
                  cleaning through seasonal campaigns.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✅ Family Subscription Plan – Build a repeat-visit culture
                  with progressive discounts.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✅ 24/7 Emergency Services – Establish the clinic as a trusted
                  urgent care provider.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  ✅ School Collaborations – Engage with students, parents, and
                  teachers for increased community involvement.
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <h3 className="ff_abel fs_23">Next Steps:</h3>
              <ol className="invoice-class mt-3">
                <li className="ff_abel ms-3 fs_23">
                  1. Launch Digital & WhatsApp Marketing Campaigns for the
                  Wedding Smile Makeover Offer.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  2. Begin School Partnerships Outreach for implementing student
                  oral health report cards.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  3. Automate WhatsApp Emergency Helpline to manage urgent
                  patient inquiries efficiently.
                </li>
                <li className="ff_abel ms-3 fs_23">
                  4. Integrate Subscription Plan Enrollment into the reception &
                  billing process.
                </li>
              </ol>
            </div>
            <div
              style={{ background: "#078513" }}
              className="mt-2 rounded-2 px-2 py-2"
            >
              <p style={{ color: "#C7F800" }} className="ff_abel mb-0 fs_23">
                By executing this plan, the clinic will drive consistent patient
                inflow, enhance its reputation, and build long-term community
                trust.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingStrategy;
