import React from "react";
import MarketingHeader from "../MarketingHeader/MarketingHeader";

const TermAndConditions = () => {
  return (
    <div className="py-3">
      <MarketingHeader heading="Introduction" />
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 ">
        <div className="p-md-5">
          <div>
            <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
              Terms & Conditions
            </h2>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              This Agreement (the “Agreement”) is made as of (-- Jan, 2025), by
              and between Doon International School (the “Company”), and Bamboo
              Ruts(“Consultant”)
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              1. Service Provider Relationship. During the term of this
              Contract, the Service Provider will provide contractual services
              to the Company as described in the Proposal hereto (the
              “Services”). Service Provider represents that Service Provider is
              duly licensed (as applicable) and has the qualifications, the
              experience and the ability to properly perform the Services. The
              service provider shall use the Service Provider’s best efforts to
              perform the Services such that the results are satisfactory to the
              Company
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              2. Fees. <br /> As consideration for the Services to be provided
              by the Service Provider and other obligations, the Company shall
              pay to the Service Provider the amounts specified on the Proposal
              hereto at the times specified therein. Monthly  Retainer Advance
              and Website Payment - 50% Advance,25% on Beta, 25% on Live.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              3. Expenses. <br /> Service Provider shall not be authorized to
              incur on behalf of the Company any expenses and will be
              responsible for all expenses incurred while performing the
              Services [except as expressly specified] unless otherwise agreed
              to by the Company’s [Title of Officer], which consent shall be
              evidenced in writing for any expenses in excess of [Expenses]. As
              a condition to receipt of reimbursement, the Service Provider
              shall be required to submit to the Company reasonable evidence
              that the amount involved was both reasonable and necessary to the
              Services provided under this Contract
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              4. Term and Termination. <br /> Service Provider shall serve as a
              Service Provider to the Company for a period commencing on
              Commencement Date and terminating on the earlier of (a) the date
              Service Provider completes the provision of the Services to the
              Company under this Contract, or (b) the date Service Provider
              shall have been paid the maximum amount of contractual
              fees.Notwithstanding the above, either party may terminate this
              Contract at any time upon 30 business days’ written notice. In the
              event of such termination, Service Provider shall be paid for any
              portion of the Services that have been performed prior to the
              termination. Should either party default in the performance of
              this Contract or materially breach any of its obligations under
              this Contract, including but not limited to Service Provider’s
              obligations under the Confidential Information and Invention
              Assignment Contract between the Company and Service Provider
              referenced below, the non-breaching party may terminate this
              Contract immediately if the breaching party fails to cure the
              breach within 15 Days business days after having received written
              notice by the non-breaching party of the breach or default.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              5. Independent Contractor.Service Provider’s relationship with the
              Company will be that of an independent contractor and not that of
              an employee. 6.Method of Provision of Services.Service Provider
              shall be solely responsible for determining the method, details
              and means of performing the Services. Service Provider may, at
              Service Provider’s own expense, employ or engage the services of
              such employees, subcontractors, partners or agents, as Service
              Provider deems necessary to perform the Services (collectively,
              the “Assistants”). The Assistants are not and shall not be
              employees of the Company, and Service Provider shall be wholly
              responsible for the professional performance of the Services by
              the Assistants such that the results are satisfactory to the
              Company.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              6.1 No Authority to Bind Company.  Consultant acknowledges and
              agrees that Consultant and its Assistants have no authority to
              enter into contracts that bind the Company or create obligations
              on the part of the Company without the prior written authorization
              of the Company.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              6.2 No Benefits.  Consultant acknowledges and agrees that
              Consultant and its Assistants shall not be eligible for any
              Company employee benefits and, to the extent Consultant otherwise
              would be eligible for any Company employee benefits but for the
              express terms of this Agreement, Consultant (on behalf of itself
              and its employees) hereby expressly declines to participate in
              such Company employee benefits.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              6.3 Withholding; Indemnification. Service Provider shall have full
              responsibility for applicable withholding taxes for all
              compensation paid to Service Provider or its Assistants under this
              Contract, and for compliance with all applicable labor and
              employment requirements with respect to Service Provider’s
              self-employment, sole proprietorship or other form of business
              organization, and with respect to the Assistants, including state
              worker’s compensation insurance coverage requirements and any U.S.
              immigration visa requirements. Service Provider agrees to
              indemnify, defend and hold the Company harmless from any liability
              for, or assessment of, any claims or penalties with respect to
              such withholding taxes, labor or employment requirements,
              including any liability for, or assessment of, withholding taxes
              imposed on the Company by the relevant taxing authorities with
              respect to any compensation paid to Service Provider or its
              Assistants.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              7. Supervision of Service Provider’s Services.All of the services
              to be performed by Service Provider, including but not limited to
              the Services, will be as agreed between Service Provider and the
              Company’s [Supervisor’s Title]. Service Provider will be required
              to report to the [Supervisor’s Title] concerning the Services
              performed under this Contract. The nature and frequency of these
              reports will be left to the discretion of the [Supervisor’s Title]
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              8. Consulting or Other Services for Competitors. Consultant
              represents and warrants that Consultant does not presently perform
              or intend to perform, during the term of the Agreement, consulting
              or other services for, or engage in or intend to engage in an
              employment relationship with, companies who businesses or proposed
              businesses in any way involve products or services which would be
              competitive with the Company’s products or services, or those
              products or services proposed or in development by the Company
              during the term of the Agreement (except for those companies
              listed). If, however, Consultant decides to do so, Consultant
              agrees that, in advance of accepting such work, Consultant will
              promptly notify the Company in writing, specifying the
              organization with which Consultant proposes to consult, provide
              services, or become employed by and to provide information
              sufficient to allow the Company to determine if such work would
              conflict with the terms of this Agreement, including the terms of
              the Confidentiality Agreement, the interests of the Company or
              further services which the Company might request of Consultant. If
              the Company determines that such work conflicts with the terms of
              this Agreement, the Company reserves the right to terminate this
              Agreement immediately. In no event shall any of the Services be
              performed for the Company at the facilities of a third party or
              using the resources of a third party
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              9. Confidentiality Agreement. Consultant shall sign, or has
              signed, a Confidentiality Agreement, on or before the date
              Consultant begins providing the Services.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              10. Conflicts with this Contract.Service Provider represents and
              warrants that neither Service Provider nor any of the Assistants
              is under any pre-existing obligation in conflict or in any way
              inconsistent with the provisions of this Contract. Service
              Provider represents and warrants that Service Provider’s
              performance of all the terms of this Contract will not breach any
              Contract to keep in confidence proprietary information acquired by
              Service Provider in confidence or in trust prior to commencement
              of this Contract. Service Provider warrants that Service Provider
              has the right to disclose and/or or use all ideas, processes,
              techniques and other information, if any, which Service Provider
              has gained from third parties, and which Service Provider
              discloses to the Company or uses in the course of performance of
              this Contract, without liability to such third parties.
              Notwithstanding the foregoing, Service Provider agrees that
              Service Provider shall not bundle with or incorporate into any
              deliveries provided to the Company herewith any third party
              products, ideas, processes, or other techniques, without the
              express, written prior approval of the Company. Service Provider
              represents and warrants that Service Provider has not granted and
              will not grant any rights or licenses to any intellectual property
              or technology that would conflict with Service Provider’s
              obligations under this Contract. Service Provider will not
              knowingly infringe upon any copyright, patent, trade secret or
              other property right of any former client, employer or third party
              in the performance of the Services.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11. Miscellaneous. <br /> 11.1  Amendments and Waivers. Any term
              of this Contract may be amended or waived only with the written
              consent of the Company.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.2 Sole Contract. This Contract, constitutes the sole contract
              of the parties and supersedes all oral negotiations and prior
              writings with respect to the subject matter hereof.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.3  Notices. Any notice required or permitted by this Contract
              shall be in writing and shall be deemed sufficient upon delivery,
              when delivered personally or by overnight courier or sent by email
              or fax (upon customary confirmation of receipt), or forty-eight
              (48) hours after being deposited in the U.S. mail as certified or
              registered mail with postage prepaid, addressed to the party to be
              notified at such party’s address or fax number as set forth on the
              signature page or as subsequently modified by written notice.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.4 Choice of Law. The validity, interpretation, construction and
              performance of this Contract shall be governed by the laws of
              India, without giving effect to the principles of conflict of
              laws.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.5 Severability. If one or more provisions of this Contract are
              held to be unenforceable under applicable law, the parties agree
              to renegotiate such provision in good faith. In the event that the
              parties cannot reach a mutually agreeable and enforceable
              replacement for such provision, then (i) such provision shall be
              excluded from this Contract, (ii) the balance of the Contract
              shall be interpreted as if such provision were so excluded and
              (iii) the balance of the Contract shall be enforceable in
              accordance with its term
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.6 Counterparts. This Contract may be executed in counterparts,
              each of which shall be deemed an original, but all of which
              together will constitute one and the same instrument.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              11.7  Advice of Counsel. EACH PARTY ACKNOWLEDGES THAT, IN
              EXECUTING THIS CONTRACT, SUCH PARTY HAS HAD THE OPPORTUNITY TO
              SEEK THE ADVICE OF INDEPENDENT LEGAL COUNSEL, AND HAS READ AND
              UNDERSTOOD ALL OF THE TERMS AND PROVISIONS OF THIS CONTRACT. THIS
              CONTRACT SHALL NOT BE CONSTRUED AGAINST ANY PARTY BY REASON OF THE
              DRAFTING OR PREPARATION HEREOF.
            </p>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              The parties have executed this Agreement as of the date first
              written above.
            </p>
          </div>
          <div className="mt-md-3 mt-1 bg-white rounded-2 p-3">
            <p className="ff_abel fs_23">
              Minimum Term <br /> The minimum term is 3 months. The reason is
              simple. We need your commitment for that length of time to achieve
              the kind of results we are both setting out to achieve. Giving you
              the ability to cancel at any time means you’re not thinking
              long-term and as you know, that isn’t the kind of thinking that
              produces epic results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndConditions;
