import React from "react";
import InvoiceHeader from "../InvoiceHeader/InvoiceHeader";
import ourServicesBrand from "../../../assets/images/png/ourServiceBrand.png";
import ourServiceContentMarketing from "../../../assets/images/png/ourServiceContentMarketing.png";
import ourServiceCreativeDesign from "../../../assets/images/png/ourServiceCreativeDesign.png";
import ourServiceInfluencerMarketing from "../../../assets/images/png/ourServiceInfluencerMarketing.png";
import ourServicePerformanceMarketing from "../../../assets/images/png/ourServicePerformanceMarketing.png";
import ourServiceSeo from "../../../assets/images/png/ourServiceSeo.png";
import ourServiceSocialMarketing from "../../../assets/images/png/ourServiceSocialMarketing.png";
import ourServiceWebAppDevelopment from "../../../assets/images/png/ourServiceWebAppDevelopment.png";
import { Col, Row } from "react-bootstrap";

const OurService = () => {
  const serviceCards = [
    {
      img: ourServicesBrand,
      title: "Brand identity <br /> and strategy",
    },
    {
      img: ourServiceSocialMarketing,
      title: "Social media <br /> marketing",
    },
    {
      img: ourServiceSeo,
      title: "Search engine <br /> optimisation",
    },
    {
      img: ourServiceContentMarketing,
      title: "Content <br /> marketing",
    },
    {
      img: ourServicePerformanceMarketing,
      title: "Performance <br /> marketing",
    },
    {
      img: ourServiceCreativeDesign,
      title: "Creative <br /> design",
    },
    {
      img: ourServiceWebAppDevelopment,
      title: "Website and app <br /> development",
    },
    {
      img: ourServiceInfluencerMarketing,
      title: "Influencer <br /> marketing and <br /> partnerships",
    },
  ];

  return (
    <div className="py-3">
      <InvoiceHeader
        heading="Area"
        opacity="opacity-0"
        displaynone="d-none d-md-block"
      />
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3">
        <div className="pt-md-5 px-lg-5">
          <div>
            <h2 className="ff_cinzel fs_48">Our Services</h2>
          </div>
          <div className="mt-3">
            <p className="ff_abel fs_23 mb-0">
              We offer a comprehensive range of services which are customised to
              meet your unique needs-
            </p>
          </div>
        </div>
        <div className="px-lg-5">
          <Row>
            {serviceCards.map((card, index) => (
              <Col key={index} className="mt-4" lg={4} xl={3} md={6}>
                <div className="py-5 bg-blur-cards h-100 d-flex flex-column justify-content-between">
                  <div className="text-center">
                    <img
                      className="object-fit-contain"
                      style={{ maxWidth: "160px", height: "113px" }}
                      src={card.img}
                      alt=""
                    />
                    <p
                      className="ff_abel fs_23 mb-0 mt-2"
                      dangerouslySetInnerHTML={{ __html: card.title }}
                    ></p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OurService;
