import React from "react";
import MarketingHeader from "../MarketingHeader/MarketingHeader";

const Investment = () => {
  return (
    <div className="py-3 investment-sec">
      <MarketingHeader heading="Investment" />
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 ">
        <div className="p-md-5">
          <div>
            <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
              Strategy
            </h2>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              Our pricing is set in a way that it won't actually cost you
              anything. Our performance offsets the cost with savings in
              multiples.
            </p>
          </div>
          <div className="mt-4">
            <div>
              <h2
                style={{ color: "#690CA3" }}
                className="ff_cinzel fs_48 fw-bold"
              >
                Our strategy for this is two-fold:
              </h2>
            </div>
            <div className="mt-md-3 mt-1">
              <p className="ff_abel fs_23">
                <span style={{ color: "#690CA3" }}>1.</span> We grab the low
                hanging fruit available first. This fund pays back your
                investment as quickly as possible.  
              </p>
              <p className="ff_abel fs_23">
                <span style={{ color: "#690CA3" }}>2.</span> We don't charge
                from customer pocket, We save from work floor efficiency
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 mt-5">
        <div className="p-md-5">
          <div>
            <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
              Packages
            </h2>
          </div>
          <div className="mt-4">
            <div className="p-3 bg-blur-investment rounded-3 h-100 d-flex flex-column justify-content-between">
              <div>
                <h2
                  style={{ color: "#690CA3" }}
                  className="ff_cinzel fs_32 fw-bold"
                >
                  Silver
                </h2>
                <div>
                  <ul>
                    <li className="ff_abel fs_20">Social Media Marketing </li>
                    <li className="ff_abel fs_20">
                      Campaign Setup / AD optimization{" "}
                    </li>
                  </ul>
                </div>
                <hr className="hr-line-investment" />
                <div className="d-flex align-items-center gap-4 justify-content-end">
                  <p className="ff_abel fs_23 mb-0">Total</p>
                  <p
                    style={{ color: "#690CA3" }}
                    className="ff_abel fs_23 mb-0"
                  >
                    ₹ 18,000
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="p-3 bg-blur-investment rounded-3 h-100 d-flex flex-column justify-content-between">
              <div>
                <h2
                  style={{ color: "#690CA3" }}
                  className="ff_cinzel fs_32 fw-bold"
                >
                  Gold
                </h2>
                <div>
                  <ul>
                    <li className="ff_abel fs_20">Social Media Marketing</li>
                    <li className="ff_abel fs_20">
                      Campaign Setup / AD optimization{" "}
                    </li>
                    <li className="ff_abel fs_20">Content Marketing</li>
                  </ul>
                </div>
                <hr className="hr-line-investment" />
                <div className="d-flex align-items-center gap-4 justify-content-end">
                  <p className="ff_abel fs_23 mb-0">Total</p>
                  <p
                    style={{ color: "#690CA3" }}
                    className="ff_abel fs_23 mb-0"
                  >
                    ₹ 25,000
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="p-3 bg-blur-investment rounded-3 h-100 d-flex flex-column justify-content-between">
              <div>
                <h2
                  style={{ color: "#690CA3" }}
                  className="ff_cinzel fs_32 fw-bold"
                >
                  Platinum
                </h2>
                <div>
                  <ul>
                    <li className="ff_abel fs_20">Social Media Marketing</li>
                    <li className="ff_abel fs_20">
                      Campaign Setup / AD optimization{" "}
                    </li>
                    <li className="ff_abel fs_20">Content Marketing</li>
                    <li className="ff_abel fs_20">
                      Influencer Marketing (As Per There Rates)
                    </li>
                    <li className="ff_abel fs_20">Social media Strategy </li>
                  </ul>
                </div>
                <hr className="hr-line-investment" />
                <div className="d-flex align-items-center gap-4 justify-content-end">
                  <p className="ff_abel fs_23 mb-0">Total</p>
                  <p
                    style={{ color: "#690CA3" }}
                    className="ff_abel fs_23 mb-0"
                  >
                    ₹ 40,000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
