import React from "react";
import MarketingHeader from "../MarketingHeader/MarketingHeader";

const MarketingIntroduction = () => {
  return (
    <div className="py-3">
      <MarketingHeader heading="Introduction" />
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 ">
        <div className="p-md-5">
          <div>
            <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
              Who We Are?
            </h2>
          </div>
          <div className="mt-md-3 mt-1">
            <p className="ff_abel fs_23">
              At <span style={{ color: "#690CA3" }}> Bamboo Ruts</span>, we
              specialise in crafting customised digital marketing solutions that
              are based on the storytelling, strategy, and measurable results.
              Just like Bamboo takes time to grow strong roots before Shooting
              skyward, we believe in creating a very strong foundation for your
              brand sustainable growth. With years of experience, our team has
              successfully worked with diverse brands across industries, helping
              them grow their digital footprint and achieve their business goals
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingIntroduction;
