import React, { useState, useRef } from "react";
import { FaPlay } from "react-icons/fa"; // Import only the play icon

import InvoiceHeader from "../InvoiceHeader/InvoiceHeader";
import mobilReelMockup from "../../../assets/images/png/dooncreativeReelMockup.png";
import doonphonemockup from "../../../assets/images/png/doonphonemockup.png";
import doonschool1 from "../../../assets/images/png/doonschool1.png";
import doonschool2 from "../../../assets/images/png/doonschool2.png";
import doonschool3 from "../../../assets/images/png/doonschool3.png";
import doonschool4 from "../../../assets/images/png/doonschool4.png";
import doonschool5 from "../../../assets/images/png/doonschool5.png";
import doonschool6 from "../../../assets/images/png/doonschool6.png";
import reelVideo from "../../../assets/videos/IMG_9209.mp4";

import { Col, Row } from "react-bootstrap";

const SocialCreative = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  // Handle play/pause button click
  const handlePlayPauseClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  // Handle video end to reset and show the play button
  const handleVideoEnd = () => {
    setIsPlaying(false);
    videoRef.current.currentTime = 0; // Reset video to start
  };

  const doonSchoolImages = [
    { img: doonschool1 },
    { img: doonschool2 },
    { img: doonschool3 },
    { img: doonschool4 },
    { img: doonschool5 },
    { img: doonschool6 },
  ];

  return (
    <div className="py-3">
      <InvoiceHeader
        heading="Introduction"
        opacity="opacity-0"
        displaynone="d-none d-md-block"
      />
      <div className="bg-blur-side-items rounded-3 p-lg-5 p-3">
        <div className="p-xxl-5">
          <Row className="align-items-center flex-column-reverse flex-md-row">
            <Col lg={6}>
              <div>
                <h2
                  className={`ff_aclonica fs_98 text-white mb-0 text-center text-lg-start mt-4 mt-md-0`}
                >
                  Brand Building Campaign
                </h2>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-center position-relative ">
                <img
                  className="mobilReelMockup"
                  src={mobilReelMockup}
                  alt="Mobile Reel Mockup"
                />

                {!isPlaying && (
                  <div
                    onClick={handlePlayPauseClick}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 10,
                      cursor: "pointer",
                    }}
                  >
                    <FaPlay
                      style={{
                        fontSize: "50px",
                        color: "#fff",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    />
                  </div>
                )}
                {/* Video element */}
                <video
                  ref={videoRef}
                  src={reelVideo}
                  controls={false}
                  className="w-100 cursor_pointer reel position-absolute"
                  style={{}}
                  onClick={handlePlayPauseClick}
                  onEnded={handleVideoEnd}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 mt-5">
        <div className="">
          <Row className="justify-content-end position-relative">
            <div className="text-center text-lg-start">
              <img
                className="doonphonemockup"
                src={doonphonemockup}
                alt="Phone Mockup"
              />
            </div>
            <Col lg={8}>
              <Row>
                {doonSchoolImages.map((image, index) => (
                  <Col xs={6} key={index}>
                    <div className="mt-3 text-center text-lg-start">
                      <img
                        className="position-relative doon-school-images"
                        style={{
                          boxShadow: "11px 10px 10.2px #00000080",
                        }}
                        src={image.img}
                        alt={`Doonschool image ${index + 1}`}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SocialCreative;
