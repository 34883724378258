import React, { useState, useRef } from "react";
import "./ServicesWeProvide.css";
import { Button, Col, Row } from "react-bootstrap";
import pandaGroup from "../../../assets/images/png/pandaGroup.webp";

import pandaPlanning from "../../../assets/images/png/pandaPlanning.webp";
import performanceMarketing from "../../../assets/images/svg/performanceMarketing.svg";
import brandingIcon from "../../../assets/images/svg/branding.svg";
import impactCampaigns from "../../../assets/images/svg/impactCampaigns.svg";
import seoIcon from "../../../assets/images/svg/seo.svg";
import onlineReputation from "../../../assets/images/svg/onlineReputation.svg";
import creativePersonalizations from "../../../assets/images/svg/creativePersonalizations.svg";
import nTierMarketing from "../../../assets/images/svg/nTierMarketing.svg";
import influencerMarketing from "../../../assets/images/svg/influencerMarketing.svg";
import videoShoots from "../../../assets/images/svg/videoShoots.svg";
import digitalStrategy from "../../../assets/images/svg/digitalStrategy.svg";
import websiteDevelopment from "../../../assets/images/svg/websiteDevelopment.svg";
import appDevelopment from "../../../assets/images/svg/appDevelopment.svg";
import softwareDevelopment from "../../../assets/images/svg/softwareDevelopment.svg";
import technologyConsulting from "../../../assets/images/svg/technologyConsulting.svg";
import readMoreGreenArrow from "../../../assets/images/svg/readMoreGreenArrow.svg";
import soilColorArrow from "../../../assets/images/svg/soilColorArrow.svg";
import { useNavigate } from "react-router-dom";

const ServicesWeProvide = () => {
  const [data, setData] = useState([
    {
      img: performanceMarketing,
      head: "Performance Marketing",
      link: `/performance-marketing`,
    },
    {
      img: brandingIcon,
      head: `Branding`,
      link: `/branding`,
    },
    {
      img: impactCampaigns,
      head: "Impact / Awareness Campaigns",
      link: `/impact-campaings`,
    },
    {
      img: seoIcon,
      head: "Search Engine Optimization",
      link: `/seo`,
    },
    {
      img: onlineReputation,
      head: "Online Reputation Management",
      link: `/online-reputation`,
    },
    {
      img: creativePersonalizations,
      head: "Creative personalizations",
      link: `/creative-personalization`,
    },
    {
      img: nTierMarketing,
      head: "N-tier marketing",
      link: `/n-tier-marketing`,
    },
    {
      img: influencerMarketing,
      head: "Influencer Marketing",
      link: `/influencer-marketing`,
    },
    {
      img: videoShoots,
      head: "Video Shoots",
      link: `/video-shoots`,
    },
    {
      img: digitalStrategy,
      head: "Digital Strategy and Planning",
      link: `/digital-strategy-and-planning`,
    },
    {
      img: websiteDevelopment,
      head: "Website Development",
      link: `/website-development`,
    },
    {
      img: appDevelopment,
      head: "App Development",
      link: `/app-development`,
    },
    {
      img: softwareDevelopment,
      head: "Software Development",
      link: `/software-development`,
    },
  ]);

  const navigate = useNavigate();

  return (
    <div className="pt-5">
      <div className="pt-5 mt-5 pt-sm-0 mt-sm-0">
        <div className="container pt-5 mt-5">
          <div className="pt-5 px-xl-5">
            <Row className="px-lg-5 pt-5 pt-sm-0">
              {data.map((value, index) => (
                <Col key={index} className="pt-4 px-lg-4" lg={4} md={4} sm={6}>
                  <div className="service-cards h-100 p-4 rounded-3 d-flex flex-column justify-content-between">
                    <div>
                      <div className="image-bg-circle p-3 d-flex align-items-center justify-content-center">
                        <img
                          style={{ width: "65px", height: "65px" }}
                          src={value.img}
                          alt={value.head}
                        />
                      </div>
                      <h2 className="ff_petrona fs_23 fw-semibold clr_light_black mt-2">
                        {value.head}
                      </h2>
                    </div>
                    <button
                      onClick={() => navigate(value.link)}
                      className="btn-read-more d-flex align-items-center justify-content-center fw-medium gap-2 mt-2"
                    >
                      Read More{" "}
                      <img
                        className="unhover-img"
                        style={{ marginTop: "2px" }}
                        src={readMoreGreenArrow}
                        alt="arrow"
                      />
                      <img
                        className="hover-img"
                        style={{ marginTop: "2px" }}
                        src={soilColorArrow}
                        alt="arrow"
                      />
                    </button>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesWeProvide;
