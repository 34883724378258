import React from "react";
import MarketingHeader from "../MarketingHeader/MarketingHeader";

const NextStep = () => {
  return (
    <div className="py-3">
      <MarketingHeader heading="NEXT STEP" />
      <div className="bg-blur-side-items rounded-3 p-md-5 p-3 ">
        <div className="p-md-5">
          <div>
            <div>
              <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
                Let's get this show on the road...
              </h2>
            </div>
            <div className="mt-md-3 mt-1">
              <p className="ff_abel fs_23">
                It goes without saying that we’d love to get started and have
                you onboard as a client.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h2 style={{ color: "#690CA3" }} className="ff_cinzel fs_48">
                Sign below
              </h2>
            </div>
            <div className="mt-md-3 mt-1">
              <p className="ff_abel fs_23">
                As with everything in our business, we make things as simple as
                possible: no back-and-forth paper contracts, just a simple
                'e-sign' built right into this proposal. You just need to agree.
              </p>
            </div>
          </div>
          <h2
            style={{ color: "#690CA3", maxWidth: "800px" }}
            className="ff_cinzel fs_64 mb-0 fw-bold text-center text-capitalize mx-auto mt-5"
          >
            We're ready to go, are you?
          </h2>
        </div>
      </div>
    </div>
  );
};

export default NextStep;
