import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/png/logo.png";

const MarketingNavbar = ({ menuItems, selected, handleNavClick }) => {
  return (
    <div className="container">
      <div className="d-flex flex-column">
        <Link to={"/"}>
          <img
            style={{ width: "200px" }}
            loading="lazy"
            className="logo position-relative mt-2"
            src={logo}
            alt="logo"
          />
        </Link>
        <div className="navbar-width">
          <ul className="list-unstyled d-flex align-items-center justify-content-between gap-4 overflow-auto">
            {menuItems.map((item, index) => {
              const isActive = selected === index;
              return (
                <li key={index} className="position-relative">
                  <Link
                    onClick={(e) => handleNavClick(e, index)}
                    to={`/bambooruts/invoice${item.link}`}
                    className={`d-flex bg-blur-side-items rounded-2 px-3 py-2 mt-4 align-items-center gap-3 ff_inter fw-medium clr_light_black opacity_70 ${
                      isActive ? "" : ""
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      style={{ width: "30px" }}
                      src={isActive ? item.selectedImage : item.unselectedImage}
                      alt={`${item.name} icon`}
                    />
                    <span
                      className={`fs_23 mb-0 text-nowrap ${
                        isActive ? "text-white" : "text-white"
                      }`}
                    >
                      {item.name}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MarketingNavbar;
