import React, { useState } from "react";
import "./ServiceDetails.css";
import { Col, Container, Row } from "react-bootstrap";

const WhyChooseUs = ({
  mapData,
  brandJourney,
  journeyHead,
  journeyHead2,
  brandJourney2,
}) => {
  const [data, setData] = useState(mapData);
  return (
    <div className="pb-5">
      <Container>
        <h2 className="ff_petrona mb-0 fs_48 text-center">
          Why Choose <span className="fw-bold">Bamboo Ruts?</span>
        </h2>
        <Row className="justify-content-center">
          {data.map((value, index) => (
            <Col className="mt-4" key={index} lg={4}>
              <div className="why-choose-us-cards rounded-2 p-4 text-center h-100">
                <h3 className="ff_inter fs_16 text-white fw-semibold">
                  {value.head}
                </h3>
                <p className="ff_inter fs_16 text-white mt-2">{value.para}</p>
              </div>
            </Col>
          ))}
        </Row>
        <div>
          <h3 className="ff_petrona mb-0 fs_48 text-center mt-5">
            {journeyHead}
          </h3>
          <p
            style={{ maxWidth: "700px" }}
            className="ff_inter fs_16 mt-2 text-center mx-auto"
          >
            {brandJourney}
          </p>
        </div>
        <div>
          <h3 className="ff_petrona mb-0 fs_48 text-center mt-5">
            {journeyHead2}
          </h3>
          <p
            style={{ maxWidth: "700px" }}
            className="ff_inter fs_16 mt-2 text-center mx-auto"
          >
            {brandJourney2}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
