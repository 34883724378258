import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import webDevelopmentService from "../assets/images/png/appDevelopment.png";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const AppDevelopment = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Business Analysis & Goal Setting{" "}
          </span>
          We begin by conducting a thorough analysis of your business objectives
          and market position. This forms the foundation for developing a robust
          digital strategy aligned with your long-term goals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Business Objectives Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Competitor Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">SWOT Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting and KPI Definition
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            2. Audience Segmentation & Persona Development{" "}
          </span>{" "}
          Understanding your target audience is crucial. We identify key
          demographics, behaviors, and preferences to create detailed buyer
          personas that guide our digital initiatives.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Audience Research</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Persona Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Customer Journey Mapping
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Behavior Analysis</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            3. Customized Digital Strategy Development{" "}
          </span>
          Based on insights gathered, we develop a tailored digital strategy
          that outlines actionable steps to achieve your objectives. This
          strategy encompasses all aspects of digital marketing, ensuring a
          cohesive approach.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Multi-Channel Strategy Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Strategy & Planning
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Campaign Ideation</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Budget Allocation & Resource Planning
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Integrated Campaign Execution </span>
          We execute multi-channel digital campaigns that drive engagement and
          conversions. Our team ensures seamless integration across platforms to
          maximize reach and impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            • Search Engine Optimization (SEO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Pay-Per-Click (PPC) Advertising
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Marketing
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Email Marketing Campaigns
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            5. Performance Monitoring & Optimization{" "}
          </span>
          Continuous monitoring and analysis are key to success. We track
          campaign performance, analyze data, and optimize strategies in
          real-time to achieve optimal results.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking & Reporting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Conversion Rate Optimization (CRO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            A/B Testing & Experimentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">ROI Measurement</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            6. Technology Integration & Innovation{" "}
          </span>
          We leverage cutting-edge technologies and tools to enhance campaign
          effectiveness and efficiency. From AI-powered analytics to marketing
          automation, we ensure your strategy remains at the forefront of
          innovation.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Marketing Technology Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Automation Implementation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Data Integration</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Predictive Analytics
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Agile Strategy Refinement </span>
          Digital landscapes evolve rapidly. We employ an agile approach to
          adapt strategies, capitalize on emerging trends, and maintain
          competitive advantage.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Strategy Reviews
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Trend Monitoring & Adaptation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Scalable Solutions</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Crisis Management Planning
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="App Development Services at Bamboo Ruts"
          mainImg={webDevelopmentService}
          mainPara="At Bamboo Ruts, we specialize in providing cutting-edge app development services that help businesses grow and stay ahead in a fast-evolving digital landscape. Our team of experienced developers and designers create custom mobile applications tailored to your specific needs, ensuring a seamless user experience across all platforms."
          mainHeading="Craft Your Digital Presence with Expert App Development"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Custom Solutions",
              para: `We understand that every business has unique goals. That’s why we offer personalized app development services to create apps that align perfectly with your business requirements.`,
            },
            {
              head: "Cross-Platform Expertise",
              para: `Our apps are designed to perform flawlessly on both iOS and Android platforms, giving you maximum reach and engagement with your audience.`,
            },
            {
              head: "User-Centric Design",
              para: `We focus on creating intuitive and engaging user interfaces that enhance the overall user experience, ensuring your app stands out in the market.`,
            },
            {
              head: "End-to-End Service",
              para: `From initial idea conception and wireframing to development, deployment, and ongoing support, we offer a full range of app development services.`,
            },
            {
              head: "Scalable & Secure",
              para: `Our apps are built to scale as your business grows, with robust security features to protect user data and ensure privacy compliance.`,
            },
          ]}
          brandJourney="Whether you're looking to develop a business app, a social networking app, or a custom enterprise solution, Bamboo Ruts delivers reliable, high-quality app development services that drive results."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default AppDevelopment;
