import React from "react";
import InvoiceHeader from "../InvoiceHeader/InvoiceHeader";

const AreaOfImprovement = () => {
  return (
    <div className="py-3">
      <InvoiceHeader
        heading="Area Of Improvement"
        opacity="opacity-0"
        displaynone="d-none d-md-block"
      />
      <div className="bg-blur-side-items rounded-3 p-lg-5 p-3">
        <div className="p-lg-5">
          <div>
            <h2 className="ff_cinzel  fs_48">Area of Improvement</h2>
          </div>
          <div className="mt-3">
            <p className="ff_abel fs_23 mb-0">
              Upon reviewing the current online presence of Doon International
              School Hisar, the following observations have been made:
            </p>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">1. Logo Placement on Website</p>
              <li className="ff_abel ms-3 fs_23">
                The school's logo appears misaligned or displaced on the
                website.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">2. Visual Content</p>
              <li className="ff_abel ms-3 fs_23">
                The website lacks high-quality images and videos of students and
                teachers
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">3. School Pics</p>
              <li className="ff_abel ms-3 fs_23">
                The heading should be named as Gallery.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">4. Image Optimization</p>
              <li className="ff_abel ms-3 fs_23">
                Uploaded images are not properly named, and meta tags and titles
                are missing.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">5. Header Alignment</p>
              <li className="ff_abel ms-3 fs_23">
                The website header is misaligned.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">6. Visitor Counter</p>
              <li className="ff_abel ms-3 fs_23">
                An unnecessary visitor counter is present, accompanied by error
                pop-ups.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">7. Social Media Links</p>
              <li className="ff_abel ms-3 fs_23">
                Social media links are broken or inactive.
              </li>
            </div>
            <div className="invoice-class mt-3">
              <p className="ff_abel mb-0 fs_23">8. Social Media Graphics</p>
              <li className="ff_abel ms-3 fs_23">
                Social media posts lack engaging graphics.
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaOfImprovement;
