import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import softwareDevelopmentService from "../assets/images/png/softwareDevelopment.png";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const SoftwareDevelopmentPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Business Analysis & Goal Setting{" "}
          </span>
          We begin by conducting a thorough analysis of your business objectives
          and market position. This forms the foundation for developing a robust
          digital strategy aligned with your long-term goals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Business Objectives Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Competitor Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">SWOT Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting and KPI Definition
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            2. Audience Segmentation & Persona Development{" "}
          </span>{" "}
          Understanding your target audience is crucial. We identify key
          demographics, behaviors, and preferences to create detailed buyer
          personas that guide our digital initiatives.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Audience Research</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Persona Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Customer Journey Mapping
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Behavior Analysis</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            3. Customized Digital Strategy Development{" "}
          </span>
          Based on insights gathered, we develop a tailored digital strategy
          that outlines actionable steps to achieve your objectives. This
          strategy encompasses all aspects of digital marketing, ensuring a
          cohesive approach.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Multi-Channel Strategy Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Strategy & Planning
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Campaign Ideation</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Budget Allocation & Resource Planning
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Integrated Campaign Execution </span>
          We execute multi-channel digital campaigns that drive engagement and
          conversions. Our team ensures seamless integration across platforms to
          maximize reach and impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            • Search Engine Optimization (SEO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Pay-Per-Click (PPC) Advertising
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Marketing
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Email Marketing Campaigns
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            5. Performance Monitoring & Optimization{" "}
          </span>
          Continuous monitoring and analysis are key to success. We track
          campaign performance, analyze data, and optimize strategies in
          real-time to achieve optimal results.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking & Reporting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Conversion Rate Optimization (CRO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            A/B Testing & Experimentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">ROI Measurement</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            6. Technology Integration & Innovation{" "}
          </span>
          We leverage cutting-edge technologies and tools to enhance campaign
          effectiveness and efficiency. From AI-powered analytics to marketing
          automation, we ensure your strategy remains at the forefront of
          innovation.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Marketing Technology Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Automation Implementation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Data Integration</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Predictive Analytics
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Agile Strategy Refinement </span>
          Digital landscapes evolve rapidly. We employ an agile approach to
          adapt strategies, capitalize on emerging trends, and maintain
          competitive advantage.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Strategy Reviews
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Trend Monitoring & Adaptation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Scalable Solutions</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Crisis Management Planning
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Software Development Services at Bamboo Ruts"
          mainImg={softwareDevelopmentService}
          mainPara="At Bamboo Ruts, we turn your vision into reality with our comprehensive software development services. Whether you need a robust web platform, custom software solutions, or enterprise-grade systems, we’ve got you covered."
          mainHeading=" Empowering Businesses with Expert Software Development Services"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Tailored Solutions for Your Needs",
              para: `No two businesses are alike, and neither are our software solutions. We take the time to understand your unique challenges and goals to deliver software that fits perfectly.`,
            },
            {
              head: "Agile Development Approach",
              para: `Our team uses agile methodologies to ensure a streamlined process, fast delivery, and flexibility to adapt to your evolving requirements.`,
            },
            {
              head: "Cutting-Edge Technology",
              para: `From the latest frameworks to modern programming languages, we leverage advanced tools to build high-performance and scalable software.`,
            },
            {
              head: "Seamless Integration",
              para: `Whether it’s integrating with your existing systems or third-party platforms, we ensure smooth and efficient functionality across all your business operations.`,
            },
            {
              head: "End-to-End Development",
              para: `From ideation and planning to development, testing, and deployment, we handle it all, so you can focus on what you do best—growing your business.`,
            },
            {
              head: "Ongoing Support and Maintenance",
              para: `Our partnership doesn’t end at deployment. We provide regular updates, maintenance, and support to keep your software running at peak performance.`,
            },
          ]}
          journeyHead="Industries We Serve"
          brandJourney="From startups to large enterprises, Bamboo Ruts has experience building solutions across various industries, including e-commerce, healthcare, education, and finance."
          journeyHead2="Your Success is Our Priority"
          brandJourney2="At Bamboo Ruts, we don’t just build software; we craft digital tools that empower your business to thrive in today’s competitive market."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default SoftwareDevelopmentPage;
