import React from "react";
import logo from "../../../assets/images/png/logo.png";
import arrowDown from "../../../assets/images/svg/arrowDown.svg";
import phoneCall from "../../../assets/images/svg/phoneCall.svg";
import websiteIcon from "../../../assets/images/svg/websiteIcon.svg";
import contactmailIcon from "../../../assets/images/svg/contactmailIcon.svg";

const ContactUs = () => {
  return (
    <div className="py-3">
      <h2
        className="text-center ff_aclonica fs_80"
        style={{
          color: "transparent",
          WebkitTextStroke: "3px white",
        }}
      >
        Thank you
      </h2>
      <p className="text-center ff_abel fs_23">
        For Considering Bamboo Ruts as a Digital Growth Partner.
      </p>
      <div className="bg-blur-side-items rounded-3 pt-lg-5 p-3 px-lg-5 pb-2">
        <div className="px-lg-5 pt-md-5 pt-3">
          <div className="text-center">
            <img
              style={{ maxWidth: "308px" }}
              className="w-100"
              src={logo}
              alt=""
            />
          </div>
          <div className="mt-3">
            <p className="ff_aclonica fs_23 text-center">
              We are excited to bring your brand story to life. <br /> Let’s
              connect and explore how we can achieve great things together.!
            </p>
          </div>
          <div className="d-lg-flex d-none align-items-center justify-content-center flex-column mt-5 pt-4 pe-3">
            <p className="ff_abel mb-0 fs_20">Visit Our WebSite</p>
            <img src={arrowDown} alt="" />
          </div>
          <div className="d-flex align-lg-items-center flex-column flex-lg-row justify-content-between mt-2">
            <div className="d-flex align-items-center gap-2">
              <img style={{ width: "30" }} src={phoneCall} alt="phoneCall" />
              <a className="ff_abel fs_23 text-black" href="tel:+917300880028">
                +91- 7300880028
              </a>
            </div>
            <div className="d-flex align-items-center gap-2">
              <img style={{ width: "30" }} src={websiteIcon} alt="phoneCall" />
              <a
                className="ff_abel fs_23 text-black text-decoration-underline"
                href="https://www.bambooruts.com/"
                target="_blank"
              >
                bambooruts.com
              </a>
            </div>
            <div className="d-flex align-items-center gap-2">
              <img
                style={{ width: "30" }}
                src={contactmailIcon}
                alt="phoneCall"
              />
              <a
                className="ff_abel fs_23 text-black"
                href="mailto:bambooruts@gmail.com"
              >
                bambooruts@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
